<style>
	.main .el-tabs__header {
		display: none;
	}
	.main2 .el-tabs__header {
		display: block;
	}
	.factory_bg {
		padding: 0px;
		width: 100%;
	}
	.factory_mask {
		background: url(../../assets/bg2.png) no-repeat top center;
		background-size: 130% auto;
		width: 100%;
		height: 100%;
		margin: 0;
		padding: 0;
	}
	.factory_title_bg {
		/* background: url(../../assets/header-bg.png) no-repeat center bottom; */
		background: url(../../assets/title-top.png) no-repeat center bottom;
		background-size: auto 100%;
		width: 100%;
		height: 40px !important;
		margin: 0;
		padding: 0;
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
		border-radius: 4px;
	}

	.factory_card {
		background: #000000aa;
		padding: 5px 10px;
		width: 170px;
		position: absolute;
		border-radius: 6px;
		z-index: 999;
	}
	.factory_card .el-radio__label {
		color: #fff;
	}
	.main2 .el-tabs__item {
		color: #fff;
	}
	.main2 .el-tabs__item.is-active {
		color: #2c6dd2;
	}
	.main_factory input {
		background: #082a54;
		border: 0px;
		color: #fff;
	}
	.main_factory input::placeholder {
		color: #1890ff;
	}

	.main_factory .el-form-item__content i {
		color: #1890ff;
	}

	.scard {
		background: url(../../assets/border1.png) no-repeat center center;
		background-size: 100% 100%;
		padding: 20px;
	}
	.scard2 {
		background: url(../../assets/border2.png) no-repeat center center;
		background-size: 100% 100%;
		padding: 20px;
	}
	.scard3 {
		background: url(../../assets/border3.png) no-repeat center center;
		background-size: 100% 100%;
		padding: 10px;
	}
	.scard5 {
		background: url(../../assets/border5.png) no-repeat center center;
		background-size: 100% 100%;
		/* background: transparent; */
		padding: 10px;
	}
	.scard3 .el-table th,
	.scard3 .el-table tr,
	.scard3 td {
		background: transparent;
		border: 0;
		color: #fff;
	}
	.scard3 .el-table--enable-row-hover .el-table__body tr:hover > td {
		background-color: transparent !important;
	}
	.scard3 .el-table::before {
		background: transparent;
	}
	.snumber {
		background: url(../../assets/num2.png) no-repeat center center;
		background-size: 100% auto;
		height: 74px;
		padding-top: 40px;
	}

	.card-detail {
		opacity: 0;
		height: 0;
		width: auto;
		transition: all 0.5s ease-in-out;
	}
	.card-detail p {
		display: none;
	}
	.factory_card1 {
		background: #000000aa;
		padding: 5px 10px;
		width: 170px;
		position: absolute;
		border-radius: 6px;
		z-index: 999;
	}
	.factory_card1 .card-detail {
		opacity: 1;
		height: 100%;
	}

	.factory_card1 .card-detail p {
		display: block;
	}
	.factory_card.card-name {
		width: auto;
	}
	.factory_card.card-name:hover {
		width: 170px;
		transition: all 0.5s ease-in-out;
	}
	.factory_card.card-name:hover .card-detail {
		opacity: 1;
		height: 100%;
		transition: all 0.5s ease-in-out;
	}
	.factory_card.card-name:hover .card-detail p {
		display: block;
	}
	.color-t {
		background-image: -webkit-linear-gradient(270deg, #fff, #8dedf8);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
</style>
<template>
	<div style="background:#081c35;width:100%;min-height:100%;">
		<el-tabs class="main" v-model="main_activeName">
			<el-tab-pane name="map" label="地图" lazy>
				<statmap id="echart_map" :height_val="clientHeight" @tofactory="to_factory" />
			</el-tab-pane>
			<el-tab-pane name="factory" label="厂区" lazy>
				<div class="factory_bg" :style="(tree[0].ver==1?'background:url('+tree[0].bg_img+') no-repeat center top;background-size: 100% 100%;':'')+'height:'+clientHeight2+'px;background-size: 100% 100%;'">
					<dir class="factory_mask">
						<el-container>
							<el-header class="factory_title_bg">
								<h1 class="color-t" style="text-align:center;line-height:40px;font-size:22px;font-weight:500;margin:0;">{{tree[0].name}}综合监控</h1>
								<div style="position:absolute;width: 227px;height: 40px;right: 20px;top: 6px;color: #e9e9e9;">
									<span style="position:absolute;top: 2px;right:80px;font-size:24px">{{current_time}}</span>
									<span style="position:absolute;top:0;right:0;color: #13bfff;font-size:12px;">{{current_date}}</span><br />
									<span style="position:absolute;bottom: 6px;right: 32px;color: #bff252;font-size:12px;">{{current_week}}</span>
								</div>
								<p style="float:left;margin-left:20px;font-size:14px;margin-top:-38px;color:#fff">
									<template v-if="!is_company">
										<span @click="to_map">企业列表</span> / <span>回路列表</span>
									</template>
								</p>
							</el-header>
							<el-container>
								<el-main>
									<!-- 回路列表 -->
									<template v-for="(item,index) in company_line_device">
										<div :key="'company_line_device'+index">
											<!-- 专业版 -->
											<div v-if="tree[0].ver==1" class="factory_card card-name" @click="to_device(item.id)" :style="'top:'+item.tag_y+'%'+';left:'+item.tag_x+'%;'">
												<p :style="'font-size:14px;color:'+(item.line_type==1?'#f7d147':'#1890ff')+';margin:0;text-align:left;'"><i class="el-icon-menu"></i> {{item.name}}</p>
												<div class="card-detail" :key="'device'+index+'-'+index2" v-for="(item2,index2) in item.children">
													<p style="color:#fff;margin:0;line-height:24px;font-size:12px">
														<template v-if="item2.type<=1">
															<i class="iconfont el-icon-dian1" style="color:#f7d147"></i> {{item2.name}}
															<span style="float:right;margin-left:5px">kWh</span>
															<span style="float:right;color:#1890ff;font-size:14px">{{item2.cur_val}}</span>
														</template>
														<template v-else-if="item2.type==2">
															<i class="iconfont el-icon-shui" style="color:#4799f7"></i> {{item2.name}}
															<span style="float:right;margin-left:5px">t</span>
															<span style="float:right;color:#1890ff;font-size:14px">{{item2.cur_val}}</span>
														</template>
														<template v-else-if="item2.type==3">
															<i class="iconfont el-icon-ranqi" style="color:#d75e32"></i> {{item2.name}}
															<span style="float:right;margin-left:5px">N㎡</span>
															<span style="float:right;color:#1890ff;font-size:14px">{{item2.cur_val}}</span>
														</template>
														<template v-else-if="item2.type==8">
															<i class="iconfont el-icon-wendu" style="color:#f7d147"></i> {{item2.name}}
															<span style="float:right;margin-left:5px">℃</span>
															<span style="float:right;color:#1890ff;font-size:14px">{{item2.cur_val}}</span>
														</template>
														<template v-else-if="item2.type==9">
															<i class="iconfont el-icon-IOTtubiao_huabanfuben" style="color:#f7d147"></i> {{item2.name}}
															<span style="float:right;margin-left:5px">%</span>
															<span style="float:right;color:#1890ff;font-size:14px">{{item2.cur_val}}</span>
														</template>
														<template v-else-if="item2.type==10">
															<i class="iconfont el-icon-yangan" style="color:#f7d147"></i> {{item2.name}}
															<span style="float:right;color:#1890ff;font-size:14px">{{item2.cur_val}}</span>
														</template>
														<template v-else-if="item2.type==11">
															<i class="iconfont el-icon-loushui" style="color:#f7d147"></i> {{item2.name}}
															<span style="float:right;color:#1890ff;font-size:14px">{{item2.cur_val}}</span>
														</template>
														<template v-else>
															<i class="iconfont el-icon-shipin" style="color:#f7d147"></i> {{item2.name}}
															<span style="float:right;color:#1890ff;font-size:14px">{{item2.cur_val}}</span>
														</template>
													</p>
												</div>
											</div>
											<!-- 体验版 -->
											<div v-else style="width:500px;height:500px">
												<div @click="to_device(item.id)" :style="'top:'+item.tag_y+'%'+';left:'+item.tag_x+'%;'" class="factory_card1">
													<p :style="'font-size:14px;color:'+(item.line_type==1?'#f7d147':'#1890ff')+';margin:0;text-align:left;'"><i class="el-icon-menu"></i> {{item.name}}</p>
													<div class="card-detail" :key="'device'+index+'-'+index2" v-for="(item2,index2) in item.children">
														<p style="color:#fff;margin:0;line-height:24px;font-size:12px">
															<template v-if="item2.type<=1">
																<i class="iconfont el-icon-dian1" style="color:#f7d147"></i> {{item2.name}}
																<span style="float:right;margin-left:5px">kWh</span>
																<span style="float:right;color:#1890ff;font-size:14px">{{item2.cur_val}}</span>
															</template>
															<template v-else-if="item2.type==2">
																<i class="iconfont el-icon-shui" style="color:#4799f7"></i> {{item2.name}}
																<span style="float:right;margin-left:5px">t</span>
																<span style="float:right;color:#1890ff;font-size:14px">{{item2.cur_val}}</span>
															</template>
															<template v-else-if="item2.type==3">
																<i class="iconfont el-icon-ranqi" style="color:#d75e32"></i> {{item2.name}}
																<span style="float:right;margin-left:5px">N㎡</span>
																<span style="float:right;color:#1890ff;font-size:14px">{{item2.cur_val}}</span>
															</template>
															<template v-else-if="item2.type==8">
																<i class="iconfont el-icon-wendu" style="color:#f7d147"></i> {{item2.name}}
																<span style="float:right;margin-left:5px">℃</span>
																<span style="float:right;color:#1890ff;font-size:14px">{{item2.cur_val}}</span>
															</template>
															<template v-else-if="item2.type==9">
																<i class="iconfont el-icon-IOTtubiao_huabanfuben" style="color:#f7d147"></i> {{item2.name}}
																<span style="float:right;margin-left:5px">%</span>
																<span style="float:right;color:#1890ff;font-size:14px">{{item2.cur_val}}</span>
															</template>
															<template v-else-if="item2.type==10">
																<i class="iconfont el-icon-yangan" style="color:#f7d147"></i> {{item2.name}}
																<span style="float:right;color:#1890ff;font-size:14px">{{item2.cur_val}}</span>
															</template>
															<template v-else-if="item2.type==11">
																<i class="iconfont el-icon-loushui" style="color:#f7d147"></i> {{item2.name}}
																<span style="float:right;color:#1890ff;font-size:14px">{{item2.cur_val}}</span>
															</template>
															<template v-else>
																<i class="iconfont el-icon-shipin" style="color:#f7d147"></i> {{item2.name}}
																<span style="float:right;color:#1890ff;font-size:14px">{{item2.cur_val}}</span>
															</template>
														</p>
													</div>
												</div>
												<img @click="to_device(item.id)" :style="'top:'+item.tag_y+'%'+';left:'+item.tag_x+'%;position: absolute;width:'+item.line_pic_w+'px;height:auto;'" :src="item.line_pic_src" />
											</div>
										</div>
									</template>
									<!-- 分时电量 -->
									<!-- <h4 style="position: absolute;bottom:174px;color:#fff;line-height:44px;margin:0;font-weight:400;width:380px"><img src="../../assets/dot.png" alt="" style="height:16px;margin:0 5px 0 15px "> 今日分时能耗 <img src="../../assets/line.png" alt="" style="float:right;margin-top:18px;height:9px"></h4>
									<div class="scard2" style="width:380px;bottom:20px;position: absolute;">
										<el-row :gutter="10">
											<el-col :span="8">
												<div class="snumber">
													<h1 style="text-align:center;margin:0;top:30px;font-size:16px;color:#fff">{{total[7]}} </h1>
													<p style="text-align:center;margin:0;color:#ffffffcc;font-size:12px"> 峰电量 kWh</p>
												</div>
											</el-col>
											<el-col :span="8">
												<div class="snumber">
													<h1 style="text-align:center;margin:0;top:30px;font-size:16px;color:#fff">{{total[8]}} </h1>
													<p style="text-align:center;margin:0;color:#ffffffcc;font-size:12px"> 平电量 kWh</p>
												</div>
											</el-col>
											<el-col :span="8">
												<div class="snumber">
													<h1 style="text-align:center;margin:0;top:30px;font-size:16px;color:#fff">{{total[9]}} </h1>
													<p style="text-align:center;margin:0;color:#ffffffcc;font-size:12px"> 谷电量 kWh</p>
												</div>
											</el-col>
										</el-row>
									</div> -->
								</el-main>
								<el-aside width="380px">
									<el-form class="main_factory" ref="searchForm2" :model="searchForm2" label-width="auto" size="mini" style="float:right;margin-top:16px;width:170px">
										<el-form-item>
											<el-date-picker style="width:170px;" value-format="yyyy-MM-dd" size="mini" v-model="searchForm2.add_time" type="date" placeholder="统计开始日期" clearable @change="get_company_line_device()" :picker-options="expireTimeOption" />
										</el-form-item>
										<el-form-item>
											<el-date-picker style="width:170px;" value-format="yyyy-MM-dd" size="mini" v-model="searchForm2.add_time2" type="date" placeholder="统计结束日期" clearable @change="get_company_line_device()" :picker-options="expireTimeOption" />
										</el-form-item>
									</el-form>
									<el-card :body-style="{padding:0}" style="clear:both;float:right;background: #000000aa;border:0;width: 170px;border-radius: 6px;padding:4px 10px;">
										<p style="color:#fff;margin:0;line-height:20px;text-align:left;font-size:14px;margin-bottom:6px;"><i class="el-icon-menu"></i> 全厂总能耗</p>
										<div v-for="(item,index) in company_info.device" :key="'factory_page-'+index">
											<p style="color:#fff;margin:0;line-height:24px;font-size:12px">
												<template v-if="item.type<=1"><i class="iconfont el-icon-dian1" style="color:#f7d147"></i> {{item.name}}： {{item.total}} kWh</template>
												<template v-else-if="item.type==2"><i class="iconfont el-icon-shui" style="color:#4799f7;"></i> {{item.name}}： {{item.total}} t</template>
												<template v-else-if="item.type==3"><i class="iconfont el-icon-ranqi" style="color:#d75e32;"></i> {{item.name}}： {{item.total}} Nm³</template>
											</p>
											<p v-if="item.type==0&&item.is_cal_line_lost==1" style="color:#fff;margin:0;line-height:24px;font-size:12px"><i class="iconfont el-icon-xiansunfenxi" style="color:#ccc;"></i> {{item.name}}线损： {{item.line_lost}} %</p>
											<p v-if="item.type==1&&item.is_cal_rc==1" style="color:#fff;margin:0;line-height:24px;font-size:12px"><i class="iconfont el-icon-eryanghuatan" style="color:green;"></i> {{item.name}}减碳： {{item.rc}} t</p>
										</div>
									</el-card>
									<h4 style="position: absolute;bottom:246px;width:380px;color:#fff;line-height:44px;margin:0;font-weight:400"><img src="../../assets/dot.png" alt="" style="height:16px;margin:0 5px 0 15px "> 今日电量能耗趋势 <img src="../../assets/line.png" alt="" style="float:right;margin-top:18px;height:9px"></h4>
									<el-card class="scard" shadow="never" :body-style="{paddingTop:0}" style="width:340px;position: absolute;bottom:20px;border:0;">
										<mechart id="factory_opts" title="" subtitle="" :style="'height: 166px'" :options="factory_opts" />
									</el-card>
								</el-aside>
							</el-container>
						</el-container>
					</dir>
				</div>
			</el-tab-pane>
			<el-tab-pane name="device" label="监测点" lazy>
				<el-header style="height:42px;">
					<p style="color:#fff;font-size:14px">
						<template v-if="!is_company">
							<span @click="to_map">企业列表</span> /</template> <span @click="back_factory">回路列表</span> / <span>监控点列表</span>
					</p>
				</el-header>
				<el-container style="padding:0 20px;">
					<el-aside width="230px">
						<div class="factory_card" style="padding-right:20px;width: 215px;">
							<!-- 一级 -->
							<p @click="get_device_list(1,tree[0].id)" style="color:#1890ff;line-height:28px;margin:0;font-size:14px;font-weight:400;"><i class="el-icon-caret-bottom"></i> <i class="el-icon-office-building"></i> {{tree[0].name}} <span v-if="cur_level==1&&cur_id==tree[0].id" style="margin-left:10px">(当前)</span></p>
							<template v-for="item in tree[0].children">
								<!-- 二级 -->
								<p v-if="item.line_type==0" @click="get_device_list(2,item.id)" style="color:#1890ff;line-height:28px;margin:0;margin-left:20px;font-size:14px;font-weight:400;" :key="item.id"> <i class="el-icon-files"></i> {{item.name}} <span v-if="cur_level==2&&cur_id==item.id" style="margin-left:10px">(当前)</span></p>
								<p v-else style="color:#f7d147;line-height:28px;margin:0;margin-left:20px;font-size:14px;font-weight:400;" :key="item.id"> <i class="el-icon-files"></i> {{item.name}}</p>

								<template v-for="item2 in item.children">
									<!-- 三级 -->
									<p style="margin:0;padding:0;font-size:14px;margin-left:40px;color:#fff" :key="item2.id" @click="get_device_list(3,item2.id)">
										<i class="el-icon-caret-right"></i> {{item2.name}}<span v-if="cur_level==3&&cur_id==item2.id" style="margin-left:10px">(当前)</span>
									</p>
								</template>
							</template>
						</div>
					</el-aside>
					<el-main style="padding-top:0;min-height:1000px">
						<el-tabs class="main2" v-model="searchForm.device_id" tab-position="left" @tab-click="device_tab_change">
							<el-tab-pane lazy v-for="item in device_list" :key="'device_list-'+item.id" :label="item.name" :name="item.id" :deviceid="item.id" :devicetype="item.type">
								<el-card v-show="item.type!=4&&item.type!=5&&item.type!=6" shadow="always" :body-style="{paddingBottom: '0'}" style="margin-bottom:15px;">
									<el-form ref="searchForm" :model="searchForm" label-width="auto" size="mini" inline>
										<el-form-item label="统计视图类型">
											<el-radio-group v-model="searchForm.view_type" size="mini" style="margin-bottom:15px" @change="change_view_type()">
												<el-radio-button label="date">日</el-radio-button>
												<el-radio-button label="month">月</el-radio-button>
												<el-radio-button label="year">年</el-radio-button>
											</el-radio-group>
										</el-form-item>
										<el-form-item label="统计日期">
											<el-date-picker value-format="yyyy-MM-dd" v-model="searchForm.add_time" :type="searchForm.view_type" placeholder="选择日期" style="width:160px" clearable @change="get_device_data()" :picker-options="expireTimeOption" />
										</el-form-item>
									</el-form>
								</el-card>
								<!-- 电表数据详情 -->
								<template v-if="item.type<=1">
									<el-container>
										<el-main style="padding:0 0px 0 0">
											<el-card shadow="always" style="margin-bottom:15px;">
												<mechart :id="'echart0-'+item.id" title="电量能耗趋势分析图" subtitle="电量值(kWh)-采集时间" style="height:350px;" :options="echart0_opts" />
											</el-card>
										</el-main>
										<!-- <el-aside style="width:340px">
											<el-card shadow="always" style="margin-bottom:15px;">
												<div slot="header" class="clearfix">
													<span>分时电量分析</span>
												</div>
												<mechartpie :id="'echart11-'+item.id" style="height:290px;" :options="echart11_opts" />
											</el-card>
										</el-aside> -->
									</el-container>
									<el-row :gutter="20">
										<el-col :span="12">
											<el-card shadow="always" style="margin-bottom:15px;" :body-style="{height: '470px',overflowY:'scroll',paddingTop:'0px'}">
												<div slot="header" class="clearfix">
													<span>监测点电量能耗排名分析</span>
												</div>
												<el-table :data="rank_device" :show-header="false">
													<el-table-column center="center" width="40">
														<template slot-scope="scope">
															<div style="background:#2C6DD2;color:#fff;width: 20px;height: 20px;font-size: 14px;line-height: 20px;text-align: center;border-radius: 20px;">{{scope.row.rank_id}}</div>
														</template>
													</el-table-column>
													<el-table-column prop="name" width="150px" />
													<el-table-column>
														<template slot-scope="scope">
															<el-progress :stroke-width="14" :percentage="scope.row.rate" />
														</template>
													</el-table-column>
													<el-table-column align="right" width="150px">
														<template slot-scope="scope">
															<span style="font-size:12px;color:#2C6DD2;">
																<small style="font-size:16px;">{{scope.row.quantity}}</small> kWh
															</span>
														</template>
													</el-table-column>
												</el-table>
											</el-card>
										</el-col>
										<el-col :span="12">
											<el-card shadow="always" style="margin-bottom:15px;" :body-style="{height: '470px',overflowY:'scroll',paddingTop:'0px'}">
												<div slot="header" class="clearfix">
													<span>时段电量能耗排名分析</span>
												</div>
												<el-table :data="rank_time" :show-header="false">
													<el-table-column center="center" width="40">
														<template slot-scope="scope">
															<div style="background:#2C6DD2;color:#fff;width: 20px;height: 20px;font-size: 14px;line-height: 20px;text-align: center;border-radius: 20px;">{{scope.row.rank_id}}</div>
														</template>
													</el-table-column>
													<el-table-column prop="time" width="80px" />
													<el-table-column>
														<template slot-scope="scope">
															<el-progress :stroke-width="14" :percentage="scope.row.rate" />
														</template>
													</el-table-column>
													<el-table-column align="right" width="150px">
														<template slot-scope="scope">
															<span style="font-size:12px;color:#333;">
																<small style="font-size:16px;">{{scope.row.quantity}}</small> kWh
															</span>
														</template>
													</el-table-column>
												</el-table>
											</el-card>
										</el-col>
									</el-row>
									<el-card style="margin-bottom:15px;">
										<mechart :id="'echart1-'+item.id" title="功率负载趋势分析图" subtitle="功率(kW)-采集时间" style="height:350px;" :options="echart1_opts" />
									</el-card>
									<el-card style="margin-bottom:15px;">
										<mechart :id="'echart2-'+item.id" title="A相功率趋势分析图" subtitle="A相功率(kW)-采集时间" style="height:350px;" :options="echart2_opts" />
									</el-card>
									<el-card style="margin-bottom:15px;">
										<mechart :id="'echart3-'+item.id" title="B相功率趋势分析图" subtitle="B相功率(kW)-采集时间" style="height:350px;" :options="echart3_opts" />
									</el-card>
									<el-card style="margin-bottom:15px;">
										<mechart :id="'echart4-'+item.id" title="C相功率趋势分析图" subtitle="C相功率(kW)-采集时间" style="height:350px;" :options="echart4_opts" />
									</el-card>
									<el-card style="margin-bottom:15px;">
										<mechart :id="'echart5-'+item.id" title="A相电流趋势分析图" subtitle="A相电流(A)-采集时间" style="height:350px;" :options="echart5_opts" />
									</el-card>
									<el-card style="margin-bottom:15px;">
										<mechart :id="'echart6-'+item.id" title="B相电流趋势分析图" subtitle="B相电流(A)-采集时间" style="height:350px;" :options="echart6_opts" />
									</el-card>
									<el-card style="margin-bottom:15px;">
										<mechart :id="'echart7-'+item.id" title="C相电流趋势分析图" subtitle="C相电流(A)-采集时间" style="height:350px;" :options="echart7_opts" />
									</el-card>

									<el-card style="margin-bottom:15px;">
										<mechart :id="'echart8-'+item.id" title="A相电压趋势分析图" subtitle="A相电压(V)-采集时间" style="height:350px;" :options="echart8_opts" />
									</el-card>
									<el-card style="margin-bottom:15px;">
										<mechart :id="'echart9-'+item.id" title="B相电压趋势分析图" subtitle="B相电压(V)-采集时间" style="height:350px;" :options="echart9_opts" />
									</el-card>
									<el-card style="margin-bottom:15px;">
										<mechart :id="'echart10-'+item.id" title="C相电压趋势分析图" subtitle="C相电压(V)-采集时间" style="height:350px;" :options="echart10_opts" />
									</el-card>
								</template>
								<!-- 水表数据详情 -->
								<template v-else-if="item.type==2">
									<el-container>
										<el-main style="padding:0 15px 0 0">
											<el-card shadow="always" style="margin-bottom:15px;">
												<mechart :id="'echart12-'+item.id" title="水量趋势分析图" subtitle="水量值(t)-采集时间" style="height:350px;" :options="echart12_opts" />
											</el-card>
										</el-main>
									</el-container>
									<el-row :gutter="20">
										<el-col :span="12">
											<el-card shadow="always" style="margin-bottom:15px;" :body-style="{height: '470px',overflowY:'scroll',paddingTop:'0px'}">
												<div slot="header" class="clearfix">
													<span>监测点水量排名分析</span>
												</div>
												<el-table :data="rank_device" :show-header="false">
													<el-table-column center="center" width="40">
														<template slot-scope="scope">
															<div style="background:#2C6DD2;color:#fff;width: 20px;height: 20px;font-size: 14px;line-height: 20px;text-align: center;border-radius: 20px;">{{scope.row.rank_id}}</div>
														</template>
													</el-table-column>
													<el-table-column prop="name" width="150px" />
													<el-table-column>
														<template slot-scope="scope">
															<el-progress :stroke-width="14" :percentage="scope.row.rate" />
														</template>
													</el-table-column>
													<el-table-column align="right" width="150px">
														<template slot-scope="scope">
															<span style="font-size:12px;color:#2C6DD2;">
																<small style="font-size:16px;">{{scope.row.quantity}}</small> t
															</span>
														</template>
													</el-table-column>
												</el-table>
											</el-card>
										</el-col>
										<el-col :span="12">
											<el-card shadow="always" style="margin-bottom:15px;" :body-style="{height: '470px',overflowY:'scroll',paddingTop:'0px'}">
												<div slot="header" class="clearfix">
													<span>时段水量排名分析</span>
												</div>
												<el-table :data="rank_time" :show-header="false">
													<el-table-column center="center" width="40">
														<template slot-scope="scope">
															<div style="background:#2C6DD2;color:#fff;width: 20px;height: 20px;font-size: 14px;line-height: 20px;text-align: center;border-radius: 20px;">{{scope.row.rank_id}}</div>
														</template>
													</el-table-column>
													<el-table-column prop="time" width="80px" />
													<el-table-column>
														<template slot-scope="scope">
															<el-progress :stroke-width="14" :percentage="scope.row.rate" />
														</template>
													</el-table-column>
													<el-table-column align="right" width="150px">
														<template slot-scope="scope">
															<span style="font-size:12px;color:#333;">
																<small style="font-size:16px;">{{scope.row.quantity}}</small> t
															</span>
														</template>
													</el-table-column>
												</el-table>
											</el-card>
										</el-col>
									</el-row>
								</template>
								<!-- 气表数据详情 -->
								<template v-else-if="item.type==3">
									<el-container>
										<el-main style="padding:0 15px 0 0">
											<el-card shadow="always" style="margin-bottom:15px;">
												<mechart :id="'echart13-'+item.id" title="气量趋势分析图" subtitle="气量值(Nm³)-采集时间" style="height:350px;" :options="echart13_opts" />
											</el-card>
										</el-main>
									</el-container>
									<el-row :gutter="20">
										<el-col :span="12">
											<el-card shadow="always" style="margin-bottom:15px;" :body-style="{height: '470px',overflowY:'scroll',paddingTop:'0px'}">
												<div slot="header" class="clearfix">
													<span>监测点气量排名分析</span>
												</div>
												<el-table :data="rank_device" :show-header="false">
													<el-table-column center="center" width="40">
														<template slot-scope="scope">
															<div style="background:#2C6DD2;color:#fff;width: 20px;height: 20px;font-size: 14px;line-height: 20px;text-align: center;border-radius: 20px;">{{scope.row.rank_id}}</div>
														</template>
													</el-table-column>
													<el-table-column prop="name" width="150px" />
													<el-table-column>
														<template slot-scope="scope">
															<el-progress :stroke-width="14" :percentage="scope.row.rate" />
														</template>
													</el-table-column>
													<el-table-column align="right" width="150px">
														<template slot-scope="scope">
															<span style="font-size:12px;color:#2C6DD2;">
																<small style="font-size:16px;">{{scope.row.quantity}}</small> Nm³
															</span>
														</template>
													</el-table-column>
												</el-table>
											</el-card>
										</el-col>
										<el-col :span="12">
											<el-card shadow="always" style="margin-bottom:15px;" :body-style="{height: '470px',overflowY:'scroll',paddingTop:'0px'}">
												<div slot="header" class="clearfix">
													<span>时段气量排名分析</span>
												</div>
												<el-table :data="rank_time" :show-header="false">
													<el-table-column center="center" width="40">
														<template slot-scope="scope">
															<div style="background:#2C6DD2;color:#fff;width: 20px;height: 20px;font-size: 14px;line-height: 20px;text-align: center;border-radius: 20px;">{{scope.row.rank_id}}</div>
														</template>
													</el-table-column>
													<el-table-column prop="time" width="80px" />
													<el-table-column>
														<template slot-scope="scope">
															<el-progress :stroke-width="14" :percentage="scope.row.rate" />
														</template>
													</el-table-column>
													<el-table-column align="right" width="150px">
														<template slot-scope="scope">
															<span style="font-size:12px;color:#333;">
																<small style="font-size:16px;">{{scope.row.quantity}}</small> Nm³
															</span>
														</template>
													</el-table-column>
												</el-table>
											</el-card>
										</el-col>
									</el-row>
								</template>
								<!-- 传感器表数据详情 -->
								<template v-else-if="item.type>=8">
									<el-card>
										<!-- 单位判断 -->
										<mechart :id="'echart14-'+item.id" :title="item.name+'趋势分析图'" :subtitle="item.name+(item.type==8?'(℃)':'')+(item.type==9?'(%)':'')+'-采集时间'" style="height:400px;" :options="echart14_opts" />
									</el-card>
								</template>
								<!-- 水尺表数据详情 -->
								<template v-else-if="item.type==7">
									<el-container>
										<el-aside width="400px">
											<h5 style="line-height:28px;font-size: 14px;color: #fff;margin:20px 0;">
												云台
												<el-radio-group size="mini" v-model="speed" style="margin:0 10px">
													<el-radio-button label="1">低速</el-radio-button>
													<el-radio-button label="2">高速</el-radio-button>
												</el-radio-group>
												<el-button size="mini" circle type="primary" icon="el-icon-top" @mousedown.native="pan_tilt(0,item.ezopen_device_serial,item.ezopen_channel_no)" @mouseup.native="pan_tilt_stop(0,item.ezopen_device_serial,item.ezopen_channel_no)" />
												<el-button size="mini" circle type="primary" icon="el-icon-bottom" @mousedown.native="pan_tilt(1,item.ezopen_device_serial,item.ezopen_channel_no)" @mouseup.native="pan_tilt_stop(1,item.ezopen_device_serial,item.ezopen_channel_no)" />
												<el-button size="mini" circle type="primary" icon="el-icon-back" @mousedown.native="pan_tilt(2,item.ezopen_device_serial,item.ezopen_channel_no)" @mouseup.native="pan_tilt_stop(2,item.ezopen_device_serial,item.ezopen_channel_no)" />
												<el-button size="mini" circle type="primary" icon="el-icon-right" @mousedown.native="pan_tilt(3,item.ezopen_device_serial,item.ezopen_channel_no)" @mouseup.native="pan_tilt_stop(3,item.ezopen_device_serial,item.ezopen_channel_no)" />
												<el-button size="mini" circle type="primary" icon="el-icon-zoom-in" @mousedown.native="pan_tilt(8,item.ezopen_device_serial,item.ezopen_channel_no)" @mouseup.native="pan_tilt_stop(8,item.ezopen_device_serial,item.ezopen_channel_no)" />
												<el-button size="mini" circle type="primary" icon="el-icon-zoom-out" @mousedown.native="pan_tilt(9,item.ezopen_device_serial,item.ezopen_channel_no)" @mouseup.native="pan_tilt_stop(9,item.ezopen_device_serial,item.ezopen_channel_no)" />
											</h5>
											<div :ref="'video-container'+item.id" :id="'video-container'+item.id" style="width:400px;height:275px;">
												&nbsp;
											</div>

										</el-aside>
										<el-main style="padding:0 0 0 20px">
											<el-card style="margin-top:0px">
												<mechart :id="'echart15-'+item.id" title="水位高度" subtitle="水位高度(cm)-采集时间" style="height:300px;" :options="echart15_opts" />
											</el-card>
										</el-main>
									</el-container>
								</template>
								<!-- 萤石云视频数据详情 -->
								<template v-else-if="item.type==5">
									<div :ref="'video-container'+item.id" :id="'video-container'+item.id" style="width:800px;height:500px;">
										&nbsp;
									</div>
								</template>
								<!-- 带云台的萤石云视频数据详情 -->
								<template v-else-if="item.type==6">
									<div :ref="'video-container'+item.id" :id="'video-container'+item.id" style="width:800px;height:500px;">
										&nbsp;
									</div>
									<h5 style="margin:0;line-height:28px;font-size: 14px;color: #fff;margin-top:20px;">
										云台控制
										<el-radio-group size="mini" v-model="speed" style="margin:0 10px">
											<el-radio-button label="1">低速</el-radio-button>
											<el-radio-button label="2">高速</el-radio-button>
										</el-radio-group>
										<el-button size="mini" circle type="primary" icon="el-icon-top" @mousedown.native="pan_tilt(0,item.ezopen_device_serial,item.ezopen_channel_no)" @mouseup.native="pan_tilt_stop(0,item.ezopen_device_serial,item.ezopen_channel_no)" />
										<el-button size="mini" circle type="primary" icon="el-icon-bottom" @mousedown.native="pan_tilt(1,item.ezopen_device_serial,item.ezopen_channel_no)" @mouseup.native="pan_tilt_stop(1,item.ezopen_device_serial,item.ezopen_channel_no)" />
										<el-button size="mini" circle type="primary" icon="el-icon-back" @mousedown.native="pan_tilt(2,item.ezopen_device_serial,item.ezopen_channel_no)" @mouseup.native="pan_tilt_stop(2,item.ezopen_device_serial,item.ezopen_channel_no)" />
										<el-button size="mini" circle type="primary" icon="el-icon-right" @mousedown.native="pan_tilt(3,item.ezopen_device_serial,item.ezopen_channel_no)" @mouseup.native="pan_tilt_stop(3,item.ezopen_device_serial,item.ezopen_channel_no)" />
										<el-button size="mini" circle type="primary" icon="el-icon-zoom-in" @mousedown.native="pan_tilt(8,item.ezopen_device_serial,item.ezopen_channel_no)" @mouseup.native="pan_tilt_stop(8,item.ezopen_device_serial,item.ezopen_channel_no)" />
										<el-button size="mini" circle type="primary" icon="el-icon-zoom-out" @mousedown.native="pan_tilt(9,item.ezopen_device_serial,item.ezopen_channel_no)" @mouseup.native="pan_tilt_stop(9,item.ezopen_device_serial,item.ezopen_channel_no)" />
									</h5>
								</template>
								<!-- iframe视频数据详情 -->
								<template v-else-if="item.type==4">
									<el-card style="margin-top:0px;width:800px">
										<iframe :src="item.url" width="800" height="450" scrolling="no" frameborder="0" allowfullscreen></iframe>
									</el-card>
								</template>
							</el-tab-pane>
						</el-tabs>
					</el-main>
				</el-container>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>
<script>
	import mmap from "../../components/mmap";
	import mechart from "../../components/mechart.vue";
	import mechartpie from "../../components/mechart_pie.vue";
	import statmap from "./page/stat_map.vue";
	import request from "../../plugins/axios";
	import VideoBg from "vue-videobg";
	import EZUIKit from "ezuikit-js";
	let player = null;
	let timer = null;
	export default {
		data() {
			return {
				is_company:
					window.localStorage.getItem("role") == "企业管理员" ? true : false,
				//主框架
				main_activeName:
					window.localStorage.getItem("role") == "企业管理员" ? "map" : "factory",
				//三页面共用
				clientHeight: "", //页面高度
				clientHeight2: "",
				company_info: {}, //选择企业信息
				expireTimeOption: {
					disabledDate(date) {
						//不能选未来的日期
						return date.getTime() >= Date.now();
					},
				},
				//factory和device页面：监测点列表
				tree: [],
				//第二级：factory页面
				company_line_device: [], //公司的二级能源设备、三级环境设备
				searchForm2: {
					add_time: "",
					add_time2: "",
				},
				total: [], //总数
				factory_opts: {},
				//device页面搜索条件
				//第三级
				detail_page: 0, //详情页面类型：0-能源、4-iframe视频、5-萤石云视频、6-萤石云视频带云台、7-水尺、8-环境传感器
				device_list: [], //设备列表
				searchForm: {
					view_type: "date",
					type: 0,
					device_id: "",
					add_time: "",
					add_time2: "",
				},
				//device页面统计图
				cur_level: 3,
				cur_id: 0,
				echart0_opts: {}, //电量能耗趋势分析图
				echart1_opts: {}, //功率负载趋势分析图
				echart2_opts: {}, //A相功率趋势分析图
				echart3_opts: {}, //B相功率趋势分析图
				echart4_opts: {}, //C相功率趋势分析图
				echart5_opts: {}, //A相电流趋势分析图
				echart6_opts: {}, //B相电流趋势分析图
				echart7_opts: {}, //C相电流趋势分析图
				echart8_opts: {}, //A相电压趋势分析图
				echart9_opts: {}, //B相电压趋势分析图
				echart10_opts: {}, //C相电压趋势分析图
				echart11_opts: {}, //分时电量饼形图
				echart12_opts: {}, //水量趋势分析图
				echart13_opts: {}, //气量趋势分析图
				echart14_opts: {}, //传感器趋势分析图
				echart15_opts: {}, //水尺趋势分析图
				rank_device: [],
				rank_time: [],
				speed: 1, //云台
				current_time: "00:00:00",
			};
		},
		computed: {
			current_date() {
				const nowDate = new Date();
				const date = {
					year: nowDate.getFullYear(),
					month: nowDate.getMonth() + 1,
					date: nowDate.getDate(),
				};
				if (date.month < 10) {
					date.month = "0" + date.month;
				}
				if (date.date < 10) {
					date.date = "0" + date.date;
				}
				return date.year + "/" + date.month + "/" + date.date;
			},
			current_week() {
				const week = new Date().getDay();
				const str = [
					"星期日",
					"星期一",
					"星期二",
					"星期三",
					"星期四",
					"星期五",
					"星期六",
				];
				return str[week];
			},
		},
		components: {
			mmap,
			mechart,
			mechartpie,
			statmap,
			VideoBg,
		},
		mounted() {
			this.start_timer();
			// 获取浏览器可视区域高度
			this.clientHeight = `${document.documentElement.clientHeight}`; //document.body.clientWidth;
			this.clientHeight2 = this.clientHeight - 64;
			let _that = this;
			window.onresize = function temp() {
				_that.clientHeight = `${document.documentElement.clientHeight}`;
				_that.clientHeight2 = _that.clientHeight - 64;
			};

			if (window.localStorage.getItem("role") == "企业管理员") {
				let factory = {
					id: window.localStorage.getItem("company_id"),
					company_id: window.localStorage.getItem("company_id"),
					lng: window.localStorage.getItem("company_lat"),
					lat: window.localStorage.getItem("company_lon"),
				};
				request({
					url: "/admin/Stat/map2/" + window.localStorage.getItem("company_id"),
					data: {
						name: this.searchForm.name,
						add_time: this.searchForm.add_time,
						add_time2: this.searchForm.add_time2,
					},
				}).then((ret) => {
					if (ret.code == 1) {
						this.to_factory(ret.data.table[0]);
					}
				});
			} else {
				this.get_tree();
			}
		},
		methods: {
			start_timer() {
				timer = setInterval(() => {
					let t = new Date();
					let h = t.getHours();
					let i = t.getMinutes();
					let s = t.getSeconds();
					h = h < 10 ? "0" + h : h;
					i = i < 10 ? "0" + i : i;
					s = s < 10 ? "0" + s : s;
					this.current_time = h + ":" + i + ":" + s;
				}, 1000);
			},
			stop_timer() {
				clearInterval(timer);
				timer = null;
			},
			//公用树状结构
			get_tree() {
				request({
					url: "/admin/Stat/tree",
					data: {
						company_id: this.company_info.company_id,
					},
				}).then((ret) => {
					if (ret.code == 1) {
						this.tree = ret.data.tree;
					}
				});
			},
			//主框架切换
			to_map() {
				this.main_activeName = "map";
				if (timer) {
					this.stop_timer();
				}
			},
			to_factory(row) {
				console.log("===========");
				console.log(row);
				this.company_info = row;
				this.get_tree(); //树状结构
				this.get_factory_stat(); //统计今日分时电量、今日用电量
				this.get_company_line_device(); //各个回路情况
				this.main_activeName = "factory";
				this.start_timer();
			},
			back_factory() {
				this.main_activeName = "factory";
				this.start_timer();
			},
			//factory页面
			get_company_line_device() {
				request({
					url: "/admin/Stat/get_company_line_device",
					data: {
						company_id: this.company_info.company_id,
						add_time: this.searchForm2.add_time,
						add_time2: this.searchForm2.add_time2,
					},
				}).then((ret) => {
					if (ret.code == 1) {
						this.company_line_device = ret.data.company_line_device;
					}
				});
			},
			get_factory_stat() {
				request({
					url: "/admin/Stat/index",
					data: {
						company_id: this.company_info.company_id,
					},
				}).then((ret) => {
					if (ret.code == 1) {
						this.total = ret.data.total;
						ret.data.echart0_opts.xAxis.axisLabel = { color: "#ffffff" };
						ret.data.echart0_opts.xAxis.axisLine = {
							show: false,
							lineStyle: { color: "#ffffff" },
						};
						ret.data.echart0_opts.toolbox = {
							show: false,
						};
						ret.data.echart0_opts.legend = {
							show: false,
						};
						ret.data.echart0_opts.grid = [
							{
								left: "20",
								right: "20",
								top: "10",
								bottom: "20",
							},
						];
						ret.data.echart0_opts.yAxis = {
							type: "value",
							axisLine: {
								show: false,
							},
							show: false,
						};
						this.factory_opts = ret.data.echart0_opts;
					}
				});
			},
			//第三级：device页面
			to_device(line_id) {
				if (timer) {
					this.stop_timer();
				}
				let _that = this;
				_that.main_activeName = "device";
				request({
					url: "/admin/Stat/get_first_point",
					data: {
						line_id,
					},
				}).then((ret) => {
					if (ret.code == 1) {
						_that.get_device_list(3, ret.data.company_point.id);
					}
				});
			},
			//获取设备列表
			get_device_list(level, id) {
				this.cur_level = level;
				this.cur_id = id;
				console.log(level, id);
				request({
					url: "/admin/Stat/get_device_list",
					data: {
						level,
						id,
					},
				}).then((ret) => {
					if (ret.code == 1) {
						this.device_list = ret.data.list;
						this.change_device(ret.data.list[0].type, ret.data.list[0].id);
					}
				});
			},
			//切换标签页、选择设备
			device_tab_change(tab) {
				console.log(tab.$attrs.devicetype, tab.$attrs.deviceid);
				this.change_device(tab.$attrs.devicetype, tab.$attrs.deviceid);
			},
			//选择设备
			change_device(type, device_id) {
				this.searchForm.type = type;
				this.searchForm.device_id = device_id;
				this.get_device_data();
			},
			//获取数据
			get_device_data() {
				this.get_device_echart_data(); //统计折线图
				if (this.searchForm.type <= 1) {
					this.get_device_time_sharing(); //电量分时分析
					this.get_device_rank(); //能耗排名分析
				} else if (this.searchForm.type == 2) {
					this.get_device_rank(); //能耗排名分析
				} else if (this.searchForm.type == 3) {
					this.get_device_rank(); //能耗排名分析
				}
			},
			//云台操作
			pan_tilt_stop(direction, deviceSerial, channelNo) {
				request({
					url: "/admin/Stat/ezopen_stop",
					data: {
						deviceSerial,
						channelNo,
						direction,
					},
				}).then((ret) => {
					if (ret.code == 1) {
						this.$notify({
							title: "操作成功",
							message: "云台控制指令已发送",
							type: "success",
						});
					} else {
						this.$notify.error({
							title: "操作失败",
							message: ret.msg,
						});
					}
				});
			},
			//云台操作
			pan_tilt(direction, deviceSerial, channelNo) {
				request({
					url: "/admin/Stat/ezopen_act",
					data: {
						deviceSerial,
						channelNo,
						direction,
						speed: this.speed,
					},
				}).then((ret) => {
					if (ret.code == 1) {
						this.$notify({
							title: "操作成功",
							message: "云台控制指令已发送",
							type: "success",
						});
					} else {
						this.$notify.error({
							title: "操作失败",
							message: ret.msg,
						});
					}
				});
			},
			//统计折线图
			get_device_echart_data() {
				if (player !== null) {
					player.stop();
					this.$refs[player.params.id][0].innerHTML = ""; //清除video
					player = null;
				}
				let type = this.searchForm.type;
				request({
					url: "/admin/Stat/echart_opt_" + this.searchForm.view_type,
					data: {
						type,
						device_id: this.searchForm.device_id,
						add_time: this.searchForm.add_time,
						add_time2: this.searchForm.add_time2,
					},
				}).then((ret) => {
					if (ret.code == 1) {
						if (type <= 1) {
							this.echart0_opts = ret.data[0];
							this.echart1_opts = ret.data[1];
							this.echart2_opts = ret.data[2];
							this.echart3_opts = ret.data[3];
							this.echart4_opts = ret.data[4];
							this.echart5_opts = ret.data[5];
							this.echart6_opts = ret.data[6];
							this.echart7_opts = ret.data[7];
							this.echart8_opts = ret.data[8];
							this.echart9_opts = ret.data[9];
							this.echart10_opts = ret.data[10];
						} else if (type == 2) {
							this.echart12_opts = ret.data; //水量折线图
						} else if (type == 3) {
							this.echart13_opts = ret.data; //气量折线图
						} else if (type >= 8) {
							this.echart14_opts = ret.data; //传感器折线图
						} else if (type == 7) {
							this.echart15_opts = ret.data; //水尺折线图
						}

						if (type == 5 || type == 6 || type == 7) {
							let width = 400;
							let height = 225;
							if (type == 5 || type == 6) {
								width = 800;
								height = 450;
							}
							//加载带云台的萤石云视频：accessToken、url、设备id
							player = new EZUIKit.EZUIKitPlayer({
								id: "video-container" + ret.data.device.id, // 视频容器ID
								accessToken: ret.data.device.accessToken,
								url: ret.data.device.url,
								width,
								height,
								template: "standard", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
								header: ["capturePicture", "save", "zoom"], // 视频上方头部控件 如果templete参数不为simple,该字段将被覆盖
								footer: ["talk", "broadcast", "hd", "fullScreen"], // 视频下方底部控件 如果template参数不为simple,该字段将被覆盖
								autoplay: true,
							});
						}
					}
				});
			},
			//能耗排名分析
			get_device_rank() {
				let view_type = this.searchForm.view_type;
				request({
					url: "/admin/Stat/rank/" + view_type,
					data: {
						company_id: this.company_info.company_id,
						add_time: this.searchForm.add_time,
						add_time2: this.searchForm.add_time2,
						search_type: this.searchForm.type,
					},
				}).then((ret) => {
					if (ret.code == 1) {
						this.rank_device = ret.data.rank_device;
						this.rank_time = ret.data.rank_time;
					}
				});
			},
			//分时统计
			get_device_time_sharing() {
				let view_type = this.searchForm.view_type;
				request({
					url: "/admin/Stat/time_sharing/" + view_type,
					data: {
						device_id: this.searchForm.device_id,
						add_time: this.searchForm.add_time,
						add_time2: this.searchForm.add_time2,
					},
				}).then((ret) => {
					if (ret.code == 1) {
						this.echart11_opts = {
							title: {
								show: true,
								text: "{a|总电量(kWh)}\n{x|" + ret.data[3].value + "}",
								x: "center",
								y: "90",
								textStyle: {
									fontSize: "12",
									fontWeight: "normal",
									rich: {
										a: {
											color: "#333",
											lineHeight: 24,
										},

										x: {
											color: "#333",
											fontSize: 16,
										},
									},
								},
							},
							series: {
								name: "",
								type: "pie",
								selectedMode: "single",
								legendHoverLink: true, //是否启用图例 hover 时的联动高亮。
								hoverAnimation: true, //是否开启 hover 在扇区上的放大动画效果。
								radius: ["55%", "100%"],
								avoidLabelOverlap: true,
								hoverAnimation: false,
								label: {
									formatter: "{b}\n{c} kWh\n{d}%",
									position: "inside",
								},
								data: [ret.data[0], ret.data[1], ret.data[2]],
								left: 10,
								right: 10,
								top: 10,
								bottom: 50,
							},
						};
					}
				});
			},
			//切换统计视图类型，重置时间搜索条件，并搜索
			change_view_type() {
				this.searchForm.add_time = "";
				this.searchForm.add_time2 = "";
				this.get_device_data();
			},
		},
	};
</script>